import Avatar from 'react-avatar';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { FaArrowLeft } from 'react-icons/fa';// Import the back arrow icon from react-icons


function EmployeeIndividualPage() {
  const { id } = useParams();
  const [employeeData, setEmployeeData] = useState([]);

  // Function to format date
  const formatDate = (dateString) => {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const fetchEmployeeData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/employeedatas/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          "Authorization": "$2a$12$tXgoc0t8Pfva7LmlyQ6/aOtB//vaEWOcdV3ElI82pAlevLYSLeg6W"
        }
      });
      const formattedEmployeeData = {
        ...response.data.employeedata,
        DOB: formatDate(response.data.employeedata.DOB),
      };
      setEmployeeData(formattedEmployeeData);
      console.log(formattedEmployeeData);
    } catch (error) {
      console.error('Error fetching individual client data:', error);
      // Handle errors, e.g., set an error state or display an error message
    }
  };

  useEffect(() => {
    if (id) {
      fetchEmployeeData();
    }
  }, [id]);

  const handleGoBack = () => {
    window.history.back(); // Go back to the previous page
  };

  return (
    <Container className="mt-5">

      <Row className="justify-content-center">
        <Col lg={8}>
          <Button
            variant="light"
            className="mb-3"
            onClick={handleGoBack}
            style={{
              backgroundColor: '#3498db', // Set background color to a shade of blue
              color: '#fff', // Set text color to white
              fontWeight: 'bold', // Make the text bold
              transition: 'background-color 0.3s ease', // Add a smooth transition effect for the background color
            }}
          >
            <FaArrowLeft style={{ marginRight: '5px' }} /> Back
          </Button>
          <div className="bg-white p-4 rounded shadow">

            <div className="text-center mb-4">
              {employeeData?.Image ? (
                <img
                  src={employeeData.Image}
                  alt="Profile"
                  className="rounded-circle border p-1"
                  style={{ width: '150px', height: '150px' }}
                />
              ) : (
                <Avatar name={employeeData.name} size="150" round={true} />
              )}
            </div>

            <h2 className="text-center text-primary mb-4">Employee Detail</h2>

            <div>
              <Row>
                <Col md={6}>
                  <Category title="Personal Information">
                    <DataItem label="Name" value={employeeData.Name} />
                    <DataItem label="Phone" value={employeeData.Phone} />
                    <DataItem label="DOB" value={employeeData.DOB && formatDate(employeeData.DOB)} />
                    <DataItem label="Aadhar Id" value={employeeData.AadharId} />
                    <DataItem label="Email" value={employeeData.Email} />
                    <DataItem
                      label="WhatsApp"
                      value={employeeData.WhatsApp ? employeeData.WhatsApp : 'N/A'}
                    />
                  </Category>
                </Col>

                <Col md={6}>
                  <Category title="Address Information">
                    <DataItem label="Address" value={employeeData.Address || 'N/A'} />
                    <DataItem label="District" value={employeeData.District} />
                    <DataItem label="PinCode" value={employeeData.PinCode} />
                  </Category>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <Category title="Referance Information">
                    <DataItem label="Referance Count" value={employeeData.ReferanceCount || 'N/A'} />
                  </Category>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

function Category({ title, children }) {
  return (
    <div style={{ marginBottom: '20px' }}>
      <h3 style={{ color: '#3498db', fontSize: '1.5em', marginBottom: '10px' }}>{title}</h3>
      {children}
    </div>
  );
}

function DataItem({ label, value }) {
  return (
    <div
      style={{
        marginBottom: '15px',
        padding: '10px',
        background: '#f9f9f9',
        borderRadius: '8px',
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <strong
        style={{
          color: '#555',
          textTransform: 'capitalize',
          flex: '1',
        }}
      >
        {label}
      </strong>
      <span style={{ color: '#333', flex: '2', textAlign: 'right' }}>{value}</span>
    </div>
  );
}

export default EmployeeIndividualPage;
