import React, { useCallback, useEffect, useRef, useState } from "react";
import "./style.css";
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import Webcam from 'react-webcam'
import Modal from 'react-bootstrap/Modal';
import CloseButton from "react-bootstrap/esm/CloseButton";
import axios from "axios";
import Swal from "sweetalert2";
import logo from "../Images/VIB Logo.svg"
import TextField from '@mui/material/TextField';
import scanner from "../Images/Scanner.png";
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { CameraAlt, Check, Construction } from "@mui/icons-material";
//import crypto from 'crypto';
//import { Buffer } from 'buffer';
//const crypto = require('crypto');
//import { Console } from "console";
//import { SHA256, enc } from 'crypto-js';
//const CryptoJS = require('crypto-js');
//const ccav = require('./ccavutil.js');



const SCANNER = `${process.env.REACT_APP_BACKEND_URL}/Scanner.png`;

const Scanner = (props) => {
    const { referalCode } = props;
    const downloadFileAtURL = (url) => {
        fetch(url)
            .then(response => response.blob())
            .then((response) => {
                const ValueURL = window.URL.createObjectURL(new Blob([response]));
                const fileName = url.split("/").pop();
                const aTag = document.createElement('a');
                aTag.href = ValueURL;
                aTag.setAttribute('download', fileName);
                document.body.appendChild(aTag);
                aTag.click();
                aTag.remove();
            })
    }

    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <div className='d-flex justify-content-end mt-3 me-3'>
                <CloseButton onClick={() => { props.onHideScanner(); }} />
            </div>
            <div className="text-center p-3">
                <img src={scanner} alt="scanner" className="img-fluid rounded" style={{ boxShadow: '0 0 20px rgba(0, 0, 0, 0.2)', width: "80%" }} />
            </div>

            <div className="py-4 d-flex flex-column align-items-center gap-4">
                <div className="text-center text-danger text-bold p-2">
                    (PLEASE MAKE SURE TO MENTION THE <br></br><b>REFERRAL CODE : {referalCode}</b> DURING THE PAYMENT PROCESS.)
                </div>
                <div className="d-flex justify-content-center gap-3">
                    <button className="btn btn-success btn-md" onClick={() => { downloadFileAtURL(SCANNER) }}>
                        <i className="bi bi-cloud-download"></i> Download
                    </button>
                    <button className="btn btn-danger btn-md" onClick={props.onHideScanner}>Close</button>
                </div>
            </div>
        </Modal>




    )
};
const videoConstraints = {
    width: 640,
    height: 480,
    facingMode: "user",
};

const CustomerRegistrationPage = () => {

    const [Show, setShow] = useState(false);
    const [Image, setImage] = useState(null);
    const [imageCaptured, setImageCaptured] = useState(false);

    const handleCloseCamera = () => setShow(false);
    const handleShowCamera = () => setShow(true);


    const Camera = (props) => {
        const webcamRef = useRef(null);
        const Take = useCallback(() => {
            const imageSrc = webcamRef.current.getScreenshot();
            setImage(imageSrc);
            setImageCaptured(true);
        }, [webcamRef]);

        return (
            <div className="">
                <Modal  {...props} backdrop="static" keyboard={false}>
                    <div className='d-flex justify-content-end'>
                        <CloseButton onClick={() => { props.onHide(); }} />
                    </div>

                    <Webcam
                        audio={false}
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        videoConstraints={videoConstraints}
                        maxScreenshotWidth={180}
                        maxScreenshotHeight={180}
                    />
                    <div className="d-flex justify-content-between m-3">
                        <button className="btn-primary btn" onClick={Take}><CameraAlt /></button>
                        <button className="btn btn-success" onClick={() => { props.onHide(); }} disabled={!imageCaptured}><Check /></button>
                    </div>
                    <div className="botter m-3 text-center">
                        {Image && <img src={Image} alt="userprofile" />}
                    </div>
                </Modal>

            </div>
        );
    }

    const [anchorElement, setanchorElementement] = React.useState(null);
    const open = Boolean(anchorElement);
    const handleClick = (event) => {
        setanchorElementement(event.currentTarget);
    };
    const handleClose = () => {
        setanchorElementement(null);
    };

    const openFileSelector = () => {
        document.getElementById('fileInput').click();
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];

        if (file) {
            if (file.type && file.type.includes('image')) {
                const reader = new FileReader();
                reader.onload = async () => {
                    const image = document.createElement('img');
                    image.src = reader.result;

                    image.onload = () => {
                        const maxWidth = 300; // Set your desired maximum width
                        const maxHeight = 300; // Set your desired maximum height

                        let newWidth = image.width;
                        let newHeight = image.height;

                        // Check and resize if necessary
                        if (image.width > maxWidth) {
                            newWidth = maxWidth;
                            newHeight = (image.height * maxWidth) / image.width;
                        }

                        if (newHeight > maxHeight) {
                            newHeight = maxHeight;
                            newWidth = (image.width * maxHeight) / image.height;
                        }

                        const canvas = document.createElement('canvas');
                        const context = canvas.getContext('2d');

                        // Set canvas dimensions
                        canvas.width = newWidth;
                        canvas.height = newHeight;

                        // Draw image on canvas with resized dimensions
                        context.drawImage(image, 0, 0, newWidth, newHeight);

                        // Convert canvas to base64 string
                        const compressedBase64 = canvas.toDataURL('image/jpeg', 0.9); // Adjust quality if needed

                        // Store the compressed base64 string in a variable
                        setImage(compressedBase64);
                    };
                };
                reader.readAsDataURL(file);
            } else {
                console.error('Selected file is not an image');
            }
        }
    };





    const [ScreenWidth, setWidth] = useState(window.innerWidth);
    // console.log(ScreenWidth);
    const [modalShow, setModalShow] = React.useState(false);
    const [referalCode, setReferalCode] = useState('');

    const [inputValue, setInputValue] = useState({
        CustomerName: "",
        PhoneNumber: "",
        SelectProof: "Aadhar",
        IdProof: "",
        SelectDistrict: "",
        Type: "",
        EmployeeCode: "",
        LandProperty: false,
        BuildHouse: false,
        Quantity: 0,
        PaymentMode: ""
    });

    const tamil_nadu_districts = [
        "Ariyalur",
        "Chengalpattu",
        "Chennai",
        "Coimbatore",
        "Cuddalore",
        "Dharmapuri",
        "Dindigul",
        "Erode",
        "Kallakurichi",
        "Kanchipuram",
        "Kanyakumari",
        "Karur",
        "Krishnagiri",
        "Madurai",
        "Nagapattinam",
        "Namakkal",
        "Nilgiris",
        "Perambalur",
        "Pudukkottai",
        "Ramanathapuram",
        "Ranipet",
        "Salem",
        "Sivaganga",
        "Tenkasi",
        "Thanjavur",
        "Theni",
        "Thoothukudi",
        "Tiruchirappalli",
        "Tirunelveli",
        "Tirupathur",
        "Tiruppur",
        "Tiruvallur",
        "Tiruvannamalai",
        "Tiruvarur",
        "Vellore",
        "Viluppuram",
        "Virudhunagar"
    ];

    const totalAmount = inputValue.Quantity * 599;

    const setValue = (e) => {
        // console.log(e.target.value);
        const { name, value } = e.target;
        setInputValue({
            ...inputValue,
            [name]: value
        })
    };

    const setCheckValue = (e) => {
        const { name, checked } = e.target;
        setInputValue((prevData) => ({
            ...prevData,
            [name]: checked,
        }));
    }

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const [error, setErrorMessage] = useState({});
    const isValidAadhar = (aadharNumber) => {
        // Regular expression for Aadhar validation
        const aadharRegex = /^\d{12}$/;
        return aadharRegex.test(aadharNumber);
    };

    const SubmitRegisterForm = async (e) => {
        e.preventDefault();
        console.log("InputValue:",inputValue);        
        const Error = {};
        if (!Image) {
            Error.Image = "Image is Required!"
        }
        if (!inputValue.CustomerName) {
            Error.CustomerName = "Name is Required!"
        }
        if (!inputValue.PhoneNumber) {
            Error.PhoneNumber = "PhoneNumber is Required!"
        }
        else if (inputValue.PhoneNumber.length !== 10) {
            Error.PhoneNumber = "Give a Correct PhoneNumber!"
        }
        if (!inputValue.IdProof) {
            Error.IdProof = "Id Proof is Required!";
        } else if (!isValidAadhar(inputValue.IdProof)) {
            Error.IdProof = "Invalid Aadhar Number!";
        }
        if (!inputValue.SelectDistrict) {
            Error.SelectDistrict = "District is Required!"
        }
        if (!inputValue.Type) {
            Error.Type = "Application type is Required!"
        }
        if (inputValue.Type === "Employee") {
            if (!inputValue.EmployeeCode) {
                Error.EmployeeCode = "Employee code is Required!"
            }
            else if (inputValue.EmployeeCode.length !== 10) {
                Error.EmployeeCode = "Give a Correct EmployeeCode!"
            }
        }
        else if (inputValue.Type !== "Employee") {
            inputValue.EmployeeCode = null;
        }
        if (inputValue.Quantity === 0) {
            Error.Quantity = "Quantity is required!"
        }
        if (!inputValue.PaymentMode) {
            Error.PaymentMode = "Payment Mode is required!"
        }

        setErrorMessage(Error);

        console.log(Error)

        if (Object.keys(Error).length === 0) {

            console.log("InputValue with Price:",inputValue, Image, totalAmount);
            try {
                console.log("Regiter Call Start")
                const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/Register`, { inputValue, Image, totalAmount },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            "Authorization": "$2a$12$tXgoc0t8Pfva7LmlyQ6/aOtB//vaEWOcdV3ElI82pAlevLYSLeg6W"
                        }
                    });
                    console.log("Regiter Call End")
                console.log("Data:" , response.data);
                console.log("Status:" , response.status);
                if (response.status === 200) {
                    setReferalCode(response.data.referalCode);
                    const refcode=response.data.referalCode;
                    // Swal.fire({
                    //     icon: 'success', 
                    //     title: 'Register Successfully!',
                    //     html: `Your referral code: <span style="font-weight: bold; color: red;">${response.data.referalCode}</span><br/><br/>Please take a screenshot for your records.`,
                    // });
                    console.log('Start');
                    await new Promise(resolve => setTimeout(resolve, 500));
                    console.log('END');
                    console.log("Trigger Call Start")
                    const res=await axios.get(`${process.env.REACT_APP_BACKEND_URL}/triggerId?refcode=${refcode}`);
                    console.log("Trigger Call End")
                    console.log(res.data);
                    console.log('====',res.data.value.id);
                    const id=res.data.value.id;
                    const order_id=id;
                    const tid=1000+order_id;
                    const totalAmountPaid = inputValue.Quantity * 599;
                    if (inputValue.PaymentMode === "Online") {
                    //          setModalShow(true);
                    //     // For Payment Process
                       
                       const requestData = {
                        "tid":tid,
                        "merchant_id": "3314106",
                        "order_id": order_id,
                        "currency":"INR",
                        "amount":totalAmountPaid,
                        "redirect_url":"https://backend.devenv.vibonline.in/success",
                        "cancel_url":"https://backend.devenv.vibonline.in/Cancel",
                        "language":"EN"
                    } 
                   console.log("Encrypt Call Start")
                   const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/encrypt`, { requestData });
                   console.log("Encrypt Call End")
                   
                    console.log("ResponseFromServer:", response);
                    if (response.status === 200) {
                        console.log("Response Success:", response);
                        window.location.href = response.data.url;                    
                    }
                    else
                    {
                        console.log("Response Failed:", response);
                    }                    
                    //For Payment Process
                    }
                
                    setInputValue({
                        CustomerName: "",
                        PhoneNumber: "",
                        SelectProof: "Aadhar Card",
                        IdProof: "",
                        SelectDistrict: "",
                        Type: "",
                        EmployeeCode: "",
                        LandProperty: false,
                        BuildHouse: false,
                        Quantity: 0,
                        PaymentMode: ""
                    });
                    setImage(null);

                }
                else if (response.status === 422) {
                    Swal.fire({
                        icon: 'warning',
                        title: 'Try Again Later!',
                    });
                }
                else if (response.status === 500) {
                    Swal.fire({
                        icon: "error",
                        title: 'Register not success,Try again !'
                    });
                }
            } catch (error) {
                Swal.fire({
                    icon: "info",
                    title: "Test Error"
                    //title: 'Bad Gate Way!'
                });
            }
        }
    }

    return (
        <div style={{
            background: "linear-gradient(to bottom, white, #dadbad)",
            maxHeight: '2000px',
        }}>
            <div className="container py-2" >
                <div className="d-flex justify-content-center align-items-center py-3">
                    <img
                        src={logo}
                        alt="Uploaded"
                        style={{ width: "234px", height: "199px" }}
                    />

                </div>

                <div className=" d-flex justify-content-center align-items-center">
                    <div className="shadow bg-white mb-2" style={{ maxWidth: "350px", border: "1px solid #3f2a05", borderRadius: "5px" }}>

                        <form className="row px-4 py-2 text-left" onSubmit={SubmitRegisterForm}>
                            <div className="d-flex flex-column align-items-center justify-content-center my-2">
                                <React.Fragment>
                                    <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                                        <Tooltip title="Upload Photo">
                                            <Avatar
                                                className="rounded-circle"
                                                src={Image}
                                                alt="Uploaded"
                                                onClick={handleClick}
                                                style={{ cursor: 'pointer' }}
                                                sx={{ width: 120, height: 120 }}
                                            />
                                            <input
                                                type="file"
                                                id="fileInput"
                                                accept="image/*,.jpeg,.jpg,.png,.gif,.bmp,.svg,.webp,.ico"
                                                onChange={handleImageChange}
                                                style={{ display: 'none' }}
                                            />
                                        </Tooltip>
                                    </Box>
                                    <Menu
                                        anchorEl={anchorElement}
                                        id="account-menu"
                                        open={open}
                                        onClose={handleClose}
                                        transformOrigin={{ horizontal: 'center', vertical: 'center' }}
                                        anchorOrigin={{ horizontal: 'center', vertical: 'center' }}
                                        className=" "
                                        style={{
                                            marginLeft: 50, // Half of the avatar width
                                            marginTop: 50, // Half of the avatar height
                                        }}
                                    >
                                        <MenuItem
                                            onClick={() => {
                                                openFileSelector();
                                                handleClose();
                                            }}
                                        >
                                            Choose from Gallery
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() => {
                                                handleShowCamera();
                                                handleClose();
                                            }}
                                        >
                                            Take photo
                                        </MenuItem>
                                    </Menu>
                                </React.Fragment>
                                {error.Image && <span style={{ color: "#3f2a05", marginTop: "5%" }}><b>{error.Image}</b></span>}

                            </div>

                            <div className="py-2">
                                <TextField
                                    className="w-100"
                                    onChange={setValue}
                                    value={inputValue.CustomerName}
                                    id="CustomerName"
                                    name="CustomerName"
                                    label="Name"

                                />
                            </div>
                            {error.CustomerName && <span style={{ color: "#3f2a05" }}><b>{error.CustomerName}</b></span>}
                            <div className="py-2">
                                <TextField
                                    className="w-100" onChange={setValue} value={inputValue.PhoneNumber} id="PhoneNumber" name="PhoneNumber" label="Phone Number" data-bs-toggle="tooltip" data-bs-placement="top" title="Please check the Note" />
                                {error.PhoneNumber && <span style={{ color: "#3f2a05" }}><b>{error.PhoneNumber}</b></span>}

                            </div>


                            <div className="py-2">
                                <TextField className="w-100" onChange={setValue} value={inputValue.IdProof} id="IdProof" name="IdProof" label="Aadhar ID" />
                                {error.IdProof && <span style={{ color: "#3f2a05" }}><b>{error.IdProof}</b></span>}
                            </div>
                            <div className="py-2">
                                <TextField
                                    select
                                    className="w-100"
                                    onChange={setValue}
                                    name="SelectDistrict"
                                    id="SelectDistrict"
                                    label="District"
                                    value={inputValue.SelectDistrict}
                                >
                                    <MenuItem value="" selected disabled>
                                        Select District
                                    </MenuItem>
                                    {tamil_nadu_districts.map((district) => (
                                        <MenuItem key={district} value={district}>
                                            {district}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                {error.SelectDistrict && <span style={{ color: "#3f2a05" }}><b>{error.SelectDistrict}</b></span>}

                            </div>
                            <div className="py-1">
                                <div className="d-flex justify-content-center align-items-center w-100">
                                    <Checkbox
                                        className="shadow-sm"
                                        id="BuildHouse"
                                        name="BuildHouse"
                                        checked={inputValue.BuildHouse}
                                        onChange={setCheckValue}
                                    />

                                    <div ><h6 style={{ fontSize: "14.5px" }}>Consider building your house with us?</h6></div>

                                </div>
                            </div>

                            <div className="py-2">
                                <TextField select
                                    className="w-100" onChange={setValue} id="Type" name="Type" label="Application Type" value={inputValue.Type} >
                                    <MenuItem value="Self">Self</MenuItem>
                                    <MenuItem value="Employee">Employee</MenuItem>
                                </TextField>
                                {error.Type && <span style={{ color: "#3f2a05" }}><b>{error.Type}</b></span>}

                            </div>


                            {inputValue.Type === "Employee" && <div className="py-2"><TextField className="w-100" onChange={setValue} value={inputValue.EmployeeCode} id="EmployeeCode" name="EmployeeCode" label="Employee Code" />
                                {error.EmployeeCode && <span style={{ color: "#3f2a05" }}><b>{error.EmployeeCode}</b></span>}
                            </div>}

                            {/* <div class="form-check mt-3">
                            <input class="form-check-input shadow-sm " type="checkbox" id="LandProperty" name="LandProperty" checked={inputValue.LandProperty} onChange={setCheckValue} />
                            <label class="form-check-label " for="flexCheckDefault">
                                Do you have a land property
                            </label>
                        </div> */}

                            <div className="py-2">

                                <TextField
                                    select
                                    className="w-100"
                                    onChange={setValue}
                                    name="Quantity"
                                    id="Quantity"
                                    label="Quantity"
                                    value={inputValue.Quantity}
                                >
                                    <MenuItem value="0">Select</MenuItem>
                                    {Array.from({ length: 20 }, (_, i) => (
                                        <MenuItem key={i} value={i + 1}>
                                            {i + 1}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                {error.Quantity && <span style={{ color: "#3f2a05" }}><b>{error.Quantity}</b></span>}

                            </div>
                            <div className="">
                                <div className="d-flex align-items-center justify-content-between py-2"  >
                                    <div className="" gutterBottom>
                                        <h6 style={{ fontSize: "15px" }}>Payment Mode</h6>
                                    </div>
                                    <RadioGroup
                                        aria-label="paymentMode"
                                        name="PaymentMode"
                                        value={inputValue.PaymentMode}
                                        onChange={setValue}
                                        row
                                    >
                                        <FormControlLabel value="Online" control={<Radio />} label="Online" />
                                        <FormControlLabel value="Offline" control={<Radio />} label="Offline" />
                                    </RadioGroup>
                                </div>

                            </div>
                            {error.PaymentMode && <span style={{ color: "#3f2a05" }}><b>{error.PaymentMode}</b></span>}
                            <div className="py-2">
                                <h6>Total Amount: <b>&#8377;{totalAmount}</b></h6>

                            </div>


                            <button type="submit" style={{ background: "#df5a04" }} >Buy Now</button>
                        </form>
                        <Camera show={Show} onHide={() => setShow(false)} />
                        <Scanner show={modalShow} onHideScanner={() => setModalShow(false)} referalCode={referalCode} />

                    </div>
                </div>
            </div>
        </div>
    );
}

export default CustomerRegistrationPage;
