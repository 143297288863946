import React, { useCallback, useEffect, useRef, useState } from "react";
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import "./style.css";
import Webcam from 'react-webcam'
import Modal from 'react-bootstrap/Modal';
import CloseButton from "react-bootstrap/esm/CloseButton";
import axios from "axios";
import Swal from "sweetalert2";

const videoConstraints = {
    width: 640,
    height: 480,
    facingMode: "user",
};

const AgentRegister = () => {
    const [Show, setShow] = useState(false);
    const [Image, setImage] = useState(null);
    // console.log(Image)

    const handleCloseCamera = () => setShow(false);
    const handleShowCamera = () => setShow(true);

    const Camera = (props) => {
        const webcamRef = useRef(null);
        const Take = useCallback(() => {
            const imageSrc = webcamRef.current.getScreenshot();
            setImage(imageSrc);
        }, [webcamRef]);

        return (
            <div className="">
                <Modal  {...props} backdrop="static" keyboard={false}>
                    <div className=' d-flex justify-content-end mt-2 me-2' ><CloseButton onClick={() => { props.onHide(); }} /></div>

                    <Webcam
                        audio={false}
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        videoConstraints={videoConstraints}
                        minScreenshotWidth={180}
                        minScreenshotHeight={180}
                    />
                    <button className="btn-primary btn" onClick={Take}>Capture photo</button>
                    <div className="botter m-3 text-center">
                        {Image && <img src={Image} alt="userprofile" />}
                    </div>

                </Modal>
            </div>
        );
    }

    const [anchorElement, setanchorElementement] = React.useState(null);
    const open = Boolean(anchorElement);
    const handleClick = (event) => {
        setanchorElementement(event.currentTarget);
    };
    const handleClose = () => {
        setanchorElementement(null);
    };


    const [ScreenWidth, setWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);



    const [inputValue, setInputValue] = useState({
        employeeName: "",
        DOB: "",
        phone: "",
        address: "",
        district: "",
        whatsapp: "",
        email: "",
        aadhar: "",
        pincode: "",
    });


    const setValue = (e) => {
        // console.log(e.target.value);
        const { name, value } = e.target;

        setInputValue(() => {
            return {
                ...inputValue,
                [name]: value
            }
        })
    };



    const openFileSelector = () => {
        document.getElementById('fileInput').click();
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.type && file.type.includes('image')) {
                const reader = new FileReader();
                reader.onload = () => {
                    const image = reader.result;
                    setImage(image);
                };
                reader.readAsDataURL(file);
            } else {
                console.error('Selected file is not an image');
            }
        }
    };

    const [error, setErrorMessage] = useState({});

    const RegisterSubmit = async (e) => {
        e.preventDefault();

        const Error = {};
        if (!Image) {
            Error.Image = "Image is Required!"
        }
        if (!inputValue.employeeName) {
            Error.employeeName = "EmployeeName is Required!"
        }
        if (!inputValue.DOB) {
            Error.DOB = "Date of Birth is Required!"
        }
        if (!inputValue.phone) {
            Error.phone = "Phone Number is Required!"
        }
        if (!inputValue.address) {
            Error.address = "Address is Required!"
        }
        if (!inputValue.district) {
            Error.district = "District is Required!"
        }
        if (!inputValue.pincode) {
            Error.pincode = "Pincode is Required!"
        }
        if (!inputValue.email) {
            Error.email = "Email is Required!"
        }
        else if (!inputValue.email.includes("@")) {
            Error.email = "It includes @ in your email!"
        }
        if (!inputValue.aadhar) {
            Error.aadhar = "Aadhar-Id is Required!"
        }

        setErrorMessage(Error);

        console.log(error);

        if (Object.keys(Error).length === 0) {

            console.log(inputValue, Image);
            try {
                const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/employeeRegister`, { inputValue, Image },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            "Authorization": "$2a$12$tXgoc0t8Pfva7LmlyQ6/aOtB//vaEWOcdV3ElI82pAlevLYSLeg6W"
                        }
                    });

                if (response.status === 200) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Register Successfully!',
                    });
                    setInputValue({
                        ...inputValue,
                        employeeName: "",
                        DOB: "",
                        phone: "",
                        address: "",
                        district: "",
                        whatsapp: "",
                        email: "",
                        aadhar: "",
                        pincode: "",
                    });
                    setImage(null);
                }
                else if (response.status === 422) {
                    Swal.fire({
                        icon: 'warning',
                        title: 'Try Again Later!',
                    });
                }
                else if (response.status === 500) {
                    Swal.fire({
                        icon: "error",
                        title: 'Register not success,Try again !'
                    });
                }
            } catch (error) {
                Swal.fire({
                    icon: "info",
                    title: 'Bad Gate Way!'
                });
            }
        }
    }

    return (
        <div className="form-data shadow">
            <div className="text-center mb-4">
                <h2 style={{ color: "#3B3663" }}>Employee Form</h2>
            </div>

            <form >
                <div className=' row '>
                    <div className=" col-md-6 d-flex align-items-center justify-content-center  ">
                        <div className="row my-4 ">
                            <React.Fragment >
                                <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center', }}>
                                    <Tooltip title="Choose Photo">
                                        <IconButton
                                            onClick={handleClick}
                                            size="small"
                                            aria-controls={open ? 'account-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={open ? 'true' : undefined}
                                        >
                                            <div className="input-group  justify-content-center align-items-center">
                                                <Avatar
                                                    className='rounded-circle '
                                                    src={Image}
                                                    alt="Uploaded"
                                                    onClick={handleClick}
                                                    style={{ cursor: 'pointer', }}
                                                    sx={ScreenWidth <= 490 ? { width: 120, height: 120 } : { width: 160, height: 160 }}
                                                />
                                                <input
                                                    type="file"
                                                    id="fileInput"
                                                    accept="image/*,.jpeg,.jpg,.png,.gif,.bmp,.svg,.webp,.ico"
                                                    onChange={handleImageChange}
                                                    style={{ display: 'none' }}
                                                />

                                            </div>
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                                <Menu
                                    anchorElement={anchorElement}
                                    id="account-menu"
                                    open={open}
                                    onClose={handleClose}
                                    onClick={handleClose}
                                    transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                                    anchorOrigin={{ horizontal: 'left', }}
                                    className=' '
                                    style={{ marginLeft: "42%", marginTop: "10%" }}
                                >
                                    <MenuItem onClick={() => {
                                        openFileSelector();
                                        handleClose()
                                    }}>
                                        Choose from Gallery
                                    </MenuItem>
                                    <MenuItem onClick={() => {
                                        handleShowCamera()
                                        handleClose()
                                    }}>
                                        Take photo
                                    </MenuItem>
                                </Menu>
                            </React.Fragment>
                            {error.Image && <span style={{ color: "red", marginTop: "25%" }} className="text-center">{error.Image}</span>}
                        </div>
                    </div>
                    <div className="col-md-6 d-flex align-items-center  ">
                        <div className="row">
                            <div className="form_input ">
                                <label htmlFor="name" className='mb-1 form-label'>Name<span className="fw-bold text-danger">*</span></label>
                                <input type="text" className="client-input shadow-sm " onChange={setValue} value={inputValue.employeeName} name="employeeName" id="employeeName" />
                            </div>
                            {error.employeeName && <span style={{ color: "red", marginBottom: "2%" }}>{error.employeeName}</span>}
                            <div className="form_input">
                                <label htmlFor="DOB" className='mb-1 form-label'>Date of Birth<span className="fw-bold text-danger">*</span></label>
                                <input type="date" onChange={setValue} value={inputValue.DOB} name="DOB" id="DOB" />
                            </div>
                            {error.DOB && <span style={{ color: "red", marginBottom: "2%" }}>{error.DOB}</span>}
                            <div className="form_input">
                                <label htmlFor="address" className='mb-1 form-label'>Address<span className="fw-bold text-danger">*</span></label>
                                <textarea className="" onChange={setValue} value={inputValue.address} name="address" id="address" />
                            </div>
                            {error.address && <span style={{ color: "red", marginBottom: "2%" }}>{error.address}</span>}
                        </div>
                    </div>

                    <div className="col-md-6 d-flex align-items-center ">
                        <div className="row ">
                            <div className="form_input">
                                <label htmlFor="district" className='mb-1 form-label'>District<span className="fw-bold text-danger">*</span></label>
                                <input type="text" onChange={setValue} value={inputValue.district} name="district" id="district" />
                            </div>
                            {error.district && <span style={{ color: "red", marginBottom: "2%" }}>{error.district}</span>}
                            <div className="form_input">
                                <label htmlFor="pincode" className='mb-1 form-label'>PinCode<span className="fw-bold text-danger">*</span></label>
                                <input type="number" onChange={setValue} value={inputValue.pincode} name="pincode" id="pincode" />
                            </div>
                            {error.pincode && <span style={{ color: "red", marginBottom: "2%" }}>{error.pincode}</span>}
                            <div className="form_input">
                                <label htmlFor="phone" className='mb-1 form-label'>Phone Number<span className="fw-bold text-danger">*</span></label>
                                <input type="tel" onChange={setValue} value={inputValue.phone} name="phone" id="phone" />
                            </div>
                            {error.phone && <span style={{ color: "red", marginBottom: "2%" }}>{error.phone}</span>}
                        </div>
                    </div>
                    <div className="col-md-6 d-flex align-items-center ">
                        <div className="row">
                            <div className="form_input">
                                <label htmlFor="email" className='mb-1 form-label'>Email<span className="fw-bold text-danger">*</span></label>
                                <input type="email" onChange={setValue} value={inputValue.email} name="email" id="email" />
                            </div>
                            {error.email && <span style={{ color: "red", marginBottom: "2%" }}>{error.email}</span>}
                            <div className="form_input">
                                <label htmlFor="aadhar" className='mb-1 form-label'>Aadhar Number<span className="fw-bold text-danger">*</span></label>
                                <input type="text" onChange={setValue} value={inputValue.aadhar} name="aadhar" id="aadhar" />
                            </div>
                            {error.aadhar && <span style={{ color: "red", marginBottom: "2%" }}>{error.aadhar}</span>}
                            <div className="form_input">
                                <label htmlFor="whatsapp" className='mb-1 form-label'>WhatsApp Number</label>
                                <input type="tel" onChange={setValue} value={inputValue.whatsapp} name="whatsapp" id="whatsapp" />
                            </div>
                            {error.whatsapp && <span style={{ color: "red", marginBottom: "2%" }}>{error.whatsapp}</span>}
                        </div>
                    </div>
                </div>
                <div className=" text-center">
                    < button className='client-input shadow-sm w-50' style={{ backgroundColor: "#3B3663", color: "white" }} onClick={RegisterSubmit} > Submit Form </button >
                </div>

            </form >
            <Camera show={Show} onHide={() => setShow(false)} />
        </div >
    );
}

export default AgentRegister;