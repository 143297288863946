import React, { useState } from 'react';
import axios from 'axios';

const AdminRegistrationPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');

  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      // Make the API request to register the user
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/auth/register`,
        { username, password },
        {
          headers: {
            'Content-Type': 'application/json',
            "Authorization": "$2a$12$tXgoc0t8Pfva7LmlyQ6/aOtB//vaEWOcdV3ElI82pAlevLYSLeg6W"
          },
        }
      );
      console.log(response)

      // Handle the response from the server
      if (response.status === 201) {
        setMessage('Admin registered successfully');
        // You may want to redirect the user to another page or show a success message.
      } else {
        setMessage(response.data.message);
      }
    } catch (error) {
      console.error(error);
      setMessage('Server error');
    }
  };

  return (
    <div>
      <h2>Register</h2>
      <form onSubmit={handleRegister}>
        <label>
          Username:
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </label>
        <br />
        <label>
          Password:
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </label>
        <br />
        <button type="submit">Register</button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
};

export default AdminRegistrationPage;
