// AdminAddEmployeePage.tsx

import React, { useState } from 'react';
import axios from 'axios';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { useNavigate } from 'react-router-dom';
import './AdminAddEmployeePage.css';

function AdminAddEmployeePage() {
  const [employeeName, setEmployeeName] = useState('');
  const [dob, setDob] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [address, setAddress] = useState('');
  const [idType, setIdType] = useState('');
  const [idNumber, setIdNumber] = useState('');
  const [photo, setPhoto] = useState(null); // Store the photo as a base64 string
  const navigate = useNavigate();

  const takePhoto = async () => {
    const image = await Camera.getPhoto({
      quality: 90,
      allowEditing: false,
      resultType: CameraResultType.Base64,
      source: CameraSource.Prompt,
    });

    setPhoto(image && image.base64Data ? `data:image/jpeg;base64,${image.base64Data}` : null);
  };

  const handleRegistration = async () => {
    try {
      const formData = new FormData();
      formData.append('photo', photo || ''); // Attach the base64 photo string
      formData.append('employeeName', employeeName);
      formData.append('dob', dob);
      formData.append('phoneNumber', phoneNumber);
      formData.append('address', address);
      formData.append('idType', idType);
      formData.append('idNumber', idNumber);

      await axios.post(`${process.env.REACT_APP_BACKEND_URL}api/employees/register`, formData, {
        headers: {
          'Content-Type': 'application/json',
          "Authorization": "$2a$12$tXgoc0t8Pfva7LmlyQ6/aOtB//vaEWOcdV3ElI82pAlevLYSLeg6W"
        }
      });

      console.log('Registration successful');
      navigate('/employeepage');
    } catch (error) {
      console.error('Registration failed', error.response.data.message);
    }
  };

  return (
    <>
      <div className='adminAddEmployeeBody'>
        <div className="wrapper">
          <div className="heading">
            <h2>Venba Interlocks</h2>
            <p>Employee Registration</p>
          </div>

          <div className="input-group photo-input">
            <label htmlFor="photo">Take photo or choose from gallery:</label>
            {photo && <img src={photo} alt="Selected" />}
            <button className="custom-btn" onClick={takePhoto}>Take/Choose Photo</button>
          </div>

          <div className="input-group">
            <label htmlFor="employeeName">Employee Name:</label>
            <input
              type="text"
              id="employeeName"
              className="input-field"
              placeholder="Employee Name"
              value={employeeName}
              onChange={(e) => setEmployeeName(e.target.value)}
            />
          </div>

          <div className="input-group">
            <label htmlFor="dob">Date of Birth:</label>
            <input
              type="date"
              id="dob"
              className="input-field"
              value={dob}
              onChange={(e) => setDob(e.target.value)}
            />
          </div>

          <div className="input-group">
            <label htmlFor="phoneNumber">Phone Number:</label>
            <input
              type="tel"
              id="phoneNumber"
              className="input-field"
              placeholder="Phone Number"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </div>

          <div className="input-group">
            <label htmlFor="address">Address:</label>
            <textarea
              id="address"
              className="input-field"
              placeholder="Address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            ></textarea>
          </div>

          <div className="input-group">
            <label htmlFor="idType">Select ID Type:</label>
            <select
              id="idType"
              className="input-field"
              value={idType}
              onChange={(e) => setIdType(e.target.value)}
            >
              <option value="" disabled>
                Select ID Type
              </option>
              <option value="aadhar">Aadhar Card</option>
              <option value="voter">Voter ID</option>
              <option value="familyCard">Family Card</option>
            </select>
          </div>

          <div className="input-group">
            <label htmlFor="idNumber">ID Number:</label>
            <input
              type="text"
              id="idNumber"
              className="input-field"
              placeholder="ID Number"
              value={idNumber}
              onChange={(e) => setIdNumber(e.target.value)}
            />
          </div>

          <div className="input-group button-input">
            <button className="custom-btn" onClick={handleRegistration}>Register</button>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminAddEmployeePage;
