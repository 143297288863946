// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ... Your existing styles ... */

@media (max-width: 767px) {
    .custom-navbar .navbar-collapse {
      text-align: center;
    }
  
    .custom-navbar .navbar-nav {
      margin-top: 10px;
    }
  
    .custom-navbar .nav-link {
      display: block;
      padding: 10px;
      color: #ffffff;
      transition: background-color 0.3s ease;
    }
  
    .custom-navbar .nav-link:hover {
      background-color: #17a2b8;
    }
  
    .custom-navbar .mobile-menu-open .nav-link {
      display: block;
    }
  
    .custom-navbar .ml-auto .nav-link,
    .custom-navbar .ml-auto .btn-outline-light {
      margin-top: 10px;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/Components/Navbar/NavBar.css"],"names":[],"mappings":"AAAA,iCAAiC;;AAEjC;IACI;MACE,kBAAkB;IACpB;;IAEA;MACE,gBAAgB;IAClB;;IAEA;MACE,cAAc;MACd,aAAa;MACb,cAAc;MACd,sCAAsC;IACxC;;IAEA;MACE,yBAAyB;IAC3B;;IAEA;MACE,cAAc;IAChB;;IAEA;;MAEE,gBAAgB;IAClB;EACF","sourcesContent":["/* ... Your existing styles ... */\n\n@media (max-width: 767px) {\n    .custom-navbar .navbar-collapse {\n      text-align: center;\n    }\n  \n    .custom-navbar .navbar-nav {\n      margin-top: 10px;\n    }\n  \n    .custom-navbar .nav-link {\n      display: block;\n      padding: 10px;\n      color: #ffffff;\n      transition: background-color 0.3s ease;\n    }\n  \n    .custom-navbar .nav-link:hover {\n      background-color: #17a2b8;\n    }\n  \n    .custom-navbar .mobile-menu-open .nav-link {\n      display: block;\n    }\n  \n    .custom-navbar .ml-auto .nav-link,\n    .custom-navbar .ml-auto .btn-outline-light {\n      margin-top: 10px;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
