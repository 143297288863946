import { Delete } from '@mui/icons-material';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

function AdminEmployeeTable() {
  const [employeeDatas, setEmployeeDatas] = useState([]);
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchEmployeeDatas();
  }, []);

  const fetchEmployeeDatas = () => {
    setLoading(true); // Set loading to true when fetching data
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/api/employeedatas`, {
        headers: {
          'Content-Type': 'application/json',
          "Authorization": "$2a$12$tXgoc0t8Pfva7LmlyQ6/aOtB//vaEWOcdV3ElI82pAlevLYSLeg6W"
        }
      })
      .then((response) => {
        setEmployeeDatas(response.data.employeedatas);
        setRecords(response.data.employeedatas);
      })
      .catch((error) => console.error('Error fetching employee datas:', error))
      .finally(() => setLoading(false)); // Set loading to false after data is fetched
  };
  // Log clientDatas whenever it changes
  useEffect(() => {
    console.log(employeeDatas);
  }, [employeeDatas]);


  // Loading spinner component
  const LoadingSpinner = () => (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );

  const columns = [
    {
      name: 'Agent Photo',
      selector: 'Image',
      cell: (row) => <img src={row.Image} style={{ width: '50px', height: '50px', padding: "5px", borderRadius: '50%' }} />,
    },
    {
      name: 'Agent Name',
      selector: 'Name',
      sortable: true,
    },
    {
      name: 'Phone Number',
      selector: 'Phone',
      sortable: true,
    },
    {
      name: 'No. of Referrals',
      selector: 'ReferanceCount',
      sortable: true,
    },
    {
      name: 'District',
      selector: 'District',
      sortable: true,
    },
    {
      name: 'Details',
      selector: 'id',
      cell: (row) => (
        <Link to={`/employeeindividualpage/${row._id}`}>
          <button className='border rounded bg-info text-white p-1' style={{ fontSize: '12px' }}>View Details</button>
        </Link>
      ),
    },
    {
      name: 'Delete',
      selector: 'id',
      cell: (row) => (
        <>
          <button className='border rounded bg-danger text-white p-1' onClick={() => handleDelete(row._id)}>
            <Delete />
          </button>
        </>
      ),
    },
  ];




  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    const newData = employeeDatas.filter((row) =>
      Object.values(row)
        .join(' ')
        .toLowerCase()
        .includes(searchValue.toLowerCase())
    );
    setRecords(newData);
  }, [searchValue]);

  const handleDelete = async (rowId) => {
    // Show a confirmation message
    const confirmResult = await Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    });

    if (confirmResult.isConfirmed) {
      try {
        // Make a PATCH request to update DeleteStatus to true
        const response = await axios.patch(`${process.env.REACT_APP_BACKEND_URL}/api/deleteRecord/${rowId}`,
          {
            headers: {
              'Content-Type': 'application/json',
              "Authorization": "$2a$12$tXgoc0t8Pfva7LmlyQ6/aOtB//vaEWOcdV3ElI82pAlevLYSLeg6W"
            }
          });

        // Check if the request was successful (status code 200-299)
        if (response.status === 200) {
          // Update the state or perform other actions as needed
          const updatedData = records.map((r) =>
            r._id === rowId ? { ...r, deleteStatus: true } : r
          );
          setRecords(updatedData);
          Swal.fire('Deleted', 'The record has been deleted successfully', 'success');
        } else {
          // Handle the error response
          Swal.fire('Error', `Failed to mark record as deleted: ${response.data.message}`, 'error');
        }
      } catch (error) {
        console.error('Error marking record as deleted:', error);
        Swal.fire('Error', 'Failed to mark record as deleted. Please try again later.', 'error');
      }
    }
  };


  const handleSearch = (event) => {
    const searchValue = event.target.value.toLowerCase();

    const newData = employeeDatas.filter((row) => {
      return (
        (row.Name && row.Name.toLowerCase().includes(searchValue)) ||
        (row.ReferanceCount && row.ReferanceCount.toString().includes(searchValue)) ||
        (row.Phone && row.Phone.toString().includes(searchValue)) ||
        (row.District && row.District.toLowerCase().includes(searchValue))
      );
    });
    setRecords(newData);
  };



  return (
    <>
      <div className="container-fluid my-5">
        <div className='px-5'>
          <h1 style={responsiveHeadingStyle} className='text-center'>Employee Details</h1>
          {loading ? (<LoadingSpinner />
          ) : (<>
            <div className="my-3 d-flex justify-content-end gap-5">

              <input
                type="text"
                className="form-control rounded col-12 col-md-4 mb-2 mb-md-0"
                placeholder="Search for Name ,District ,Phone number "
                onChange={handleSearch}
              />
            </div>

            <DataTable
              columns={columns}
              data={records}
              fixedHeader
              pagination
              responsive
              striped
              highlightOnHover
              customStyles={{
                headRow: {
                  style: {
                    backgroundColor: '#3498db',
                    color: '#fff',
                    fontSize: '16px',
                    fontWeight: 'bold',
                    border: '1px solid #ddd', // Added border style
                  },
                },
                headCells: {
                  style: {
                    paddingLeft: '8px',
                    paddingRight: '8px',
                  },
                },
                rows: {
                  style: {
                    fontSize: '14px',
                    border: '1px solid #ddd', // Added border style
                  },
                },
                cells: {
                  style: {
                    paddingLeft: '8px',
                    paddingRight: '8px',
                    wordBreak: 'break-word',
                  },
                },
              }}
            />
          </>)}
        </div>
      </div>
    </>
  );
}
const responsiveHeadingStyle = {
  fontFamily: 'Arial, sans-serif',
  fontSize: '36px',
  fontWeight: 'bold',
  marginBottom: '20px',
  background: 'linear-gradient(to right, #3498db, #8e44ad)',
  WebkitBackgroundClip: 'text',
  color: 'transparent',
  textShadow: '0 2px 5px rgba(0, 0, 0, 0.3)',
  animation: 'fadeInUp 0.5s ease-in-out',

  // Responsive Font Size
  '@media (max-width: 768px)': {
    fontSize: '28px',
  },
  '@media (max-width: 576px)': {
    fontSize: '24px',
  },
};

export default AdminEmployeeTable;
