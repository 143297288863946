import React from 'react'
import logo from "../Images/VIB Logo.svg"
import { Link } from 'react-router-dom'

const ReturnPolicy = () => {
  return (
    <div className='container'>
      <div className='d-flex justify-content-center'>
        <Link to='/'><img src={logo} alt='logo'/></Link>
      </div>
      <div style={{display:'flex',flexDirection:'column',gap:'10px',margin:'50px'}}>
      <h2 className='text-center'>Returns and Refunds Policy</h2>
    <h4>Conditions for Returns:</h4>
    <div>
    <p>In order for the Goods to be eligible for a return, please make sure that:</p>
    <ul>
        <li>The Goods were purchased in the last 24 hrs.</li>
        <li>The Goods are in the original packaging.</li>
    </ul>
    </div>
    <div>
    <p>The following Goods cannot be returned:</p>
    <ul>
        <li>The supply of Goods made to Your specifications or clearly personalized.</li>
        <li>The supply of Goods which according to their nature are not suitable to be returned.</li>
        <li>The supply of Goods which are, after delivery, according to their nature, inseparably mixed with other items.</li>
    </ul>
    </div>
    <p>We reserve the right to refuse returns of any merchandise that does not meet the above return conditions in our sole discretion.</p>
    <p>Only regular priced Goods may be refunded. Unfortunately, Goods on sale cannot be refunded. This exclusion may not apply to You if it is not permitted by applicable law.</p>
    <div>
    <h4>Returning Goods:</h4>
    <p>You are responsible for the cost and risk of returning the Goods to Us. You should send the Goods at the following address:</p>
    <p>[Your Return Address Here]</p>
    <p>We cannot be held responsible for Goods damaged or lost in return shipment. Therefore, We recommend to cross check the product and make sure they are in the original state as they were delivered. We are unable to issue a refund without actual receipt of the Goods or proof of received return delivery.</p>
    </div>
    <div>
    <h4>Contact Us</h4>
    <p>If you have any questions about our Returns and Refunds Policy, please contact us:</p>
    <p>[Your Contact Information Here]</p>
    </div>
    </div>
    </div>
  )
}

export default ReturnPolicy
