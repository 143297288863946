import React, { useState} from 'react';
import { useSpring} from 'react-spring';


const LuckyDraw = () => {
  const [result, setResult] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [buttonAnimation, setButtonAnimation] = useSpring(() => ({
    transform: 'scale(1)',
    config: { tension: 300, friction: 10 },
  }));

  let confettiRef = null;

  const getInstance = (instance) => {
    confettiRef = instance;
  };

  const popupSpring = useSpring({
    transform: isModalOpen ? 'scale(1)' : 'scale(0)',
    opacity: isModalOpen ? 1 : 0,
  });

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setError(null);
  };

  const handleClick = () => {
    setButtonAnimation({ transform: 'scale(0.95)' }); // Set button scale on click
    fetchRandomEntry();
  };

  const fetchRandomEntry = async () => {
    try {
      setIsLoading(true);

      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/luckydraw`, {
        headers: {
          'Content-Type': 'application/json',
          "Authorization": "$2a$12$tXgoc0t8Pfva7LmlyQ6/aOtB//vaEWOcdV3ElI82pAlevLYSLeg6W"
        }
      });
      const data = await response.json();

      if (response.ok) {
        setResult(data.registerData);
        openModal();
        if (confettiRef) {
          confettiRef({
            particleCount: 100,
            spread: 70,
            origin: { y: 0.6 },
          });
        }
      } else {
        setError(data.message || 'Something went wrong.');
      }
    } catch (error) {
      setError('Something went wrong. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {/* <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        fontFamily: 'Arial, sans-serif',
      }}
    >
      <h2 style={{ marginBottom: '20px', fontSize: '2em' }}> Lucky Draw </h2>
      {isLoading ? (
        <BeatLoader color="#3498db" loading={isLoading} size={20} />
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <animated.button
            style={{
              backgroundColor: '#3498db',
              border: 'none',
              color: 'white',
              fontSize: '1.5em',
              padding: '30px',
              cursor: 'pointer',
              borderRadius: '50%',
              animation: isLoading ? 'pulse 1.5s infinite' : 'none',
              transition: 'background-color 0.3s ease',
              width: '60%',
              height: '60%',
              maxWidth: '300px',
              transform: buttonAnimation.transform, // Apply animation
            }}
            onClick={handleClick}
            disabled={isLoading}
          >
            Spin to Win!
          </animated.button>
          <p style={{ fontSize: '1.5em', marginTop: '10px' }}>
            Click below to Spin and Win!
          </p>
        </div>
      )}
      <animated.div style={popupSpring}>
        <Modal
          show={isModalOpen}
          onHide={closeModal}
          style={{
            color: '#333',
            background: 'rgba(255, 255, 255, 0.9)',
            border: 'none',
            borderRadius: '15px',
            boxShadow: '0 0 20px rgba(0, 0, 0, 0.3)',
          }}
        >
          <ReactCanvasConfetti
            refConfetti={getInstance}
            style={{
              position: 'fixed',
              pointerEvents: 'none',
              width: '100%',
              height: '100%',
              top: 0,
              left: 0,
            }}
          />
          <Modal.Header closeButton />
          <Modal.Title
            style={{
              color: '#e74c3c',
              fontSize: '2.5em',
              textAlign: 'center',
              marginBottom: '20px',
            }}
          >
            Congratulations!
          </Modal.Title>
          <Modal.Body style={{ textAlign: 'center' }}>
            {error ? (
              <p
                style={{
                  color: '#e74c3c',
                  fontWeight: 'bold',
                  marginTop: '20px',
                }}
              >
                {error}
              </p>
            ) : (
              result && (
                <div style={{ margin: '20px' }}>
                  <img
                    src={result.Image}
                    alt="Winner"
                    style={{
                      boxShadow: '0 0 20px rgba(0, 0, 0, 0.3)',
                      width: '100%',
                      height: 'auto',
                      margin: '0 auto',
                    }}
                    className="img-fluid  mb-3"
                  />
                  <div style={{ marginTop: '20px' }}>
                    <p style={{ fontSize: '1.5em', color: '#555' }}>
                      <strong>Name:</strong> {result.Name}
                    </p>
                    <p style={{ fontSize: '1.5em', color: '#555' }}>
                      <strong>Referral Code:</strong> {result.ReferalCode}
                    </p>
                  </div>
                </div>
              )
            )}
          </Modal.Body>
        </Modal>
      </animated.div>
    </div> */}
    <div className='d-flex flex-column align-items-center'>
  
  <p className='text-center' style={{ fontSize: '24px', color: '#333', marginTop: '20px' }}>
   Under Construction
  </p>
  <p className='text-center' style={{ fontSize: '16px', color: '#666', marginBottom: '20px' }}>
    We're working hard to improve your experience. Check back soon!
  </p>
</div>




    </>
  );
};

export default LuckyDraw;
