import React from 'react'
import logo from "../Images/VIB Logo.svg"
import { Link } from 'react-router-dom'

const TermsCondition = () => {
  return (
    <div className='container'>
        <div className='d-flex justify-content-center'>
        <Link to='/'><img src={logo} alt='logo'/></Link>
      </div>
        <div style={{display:'flex',flexDirection:'column',gap:'10px',margin:'50px'}}>
      <h2 className='text-center'>Terms of Service</h2>
    <div>
        <p>This website is operated by Venba. Throughout the site, the terms “we”, “us” and “our” refer to Venba. Venba offers this website, including all information, tools and services available from this site to you, the user, conditioned upon your acceptance of all terms, conditions, policies and notices stated here.</p>
        <p>Please read these Terms of Service carefully before accessing or using our website. By accessing or using any part of the site, you agree to be bound by these Terms of Service. If you do not agree to all the terms and conditions of this agreement, then you may not access the website or use any services. If these Terms of Service are considered an offer, acceptance is expressly limited to these Terms of Service.</p>
        <p>You must not:</p>
        <ul>
            <li>Republish material from Venba</li>
            <li>Sell, rent or sub-license material from Venba</li>
            <li>Reproduce, duplicate or copy material from Venba</li>
            <li>Redistribute content from Venba</li>
        </ul>
    </div>

    <div>
        <h4>Online Store Terms</h4>
        <p>You may not use our products for any illegal or unauthorized purpose nor may you, in the use of the Service, violate any laws in your jurisdiction (including but not limited to copyright laws).</p>
        <p>You must not transmit any worms or viruses or any code of a destructive nature.</p>
        <p>A breach or violation of any of the Terms will result in an immediate termination of your Services.</p>
    </div>

    <div>
        <h4>General Conditions</h4>
        <p>We reserve the right to refuse service to anyone for any reason at any time.</p>
        <p>You understand that your content (not including credit card information), may be transferred unencrypted and involve (a) transmissions over various networks; and (b) changes to conform and adapt to technical requirements of connecting networks or devices. Credit card information is always encrypted during transfer over networks.</p>
        <p>You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Service, use of the Service, or access to the Service or any contact on the website through which the service is provided, without express written permission by us.</p>
        <p>The headings used in this agreement are included for convenience only and will not limit or otherwise affect these Terms.</p>
    </div>

    <div>
        <h4>Accuracy, Completeness and Timeliness of Information</h4>
        <p>We are not responsible if information made available on this site is not accurate, complete or current. The material on this site is provided for general information only and should not be relied upon or used as the sole basis for making decisions without consulting primary, more accurate, more complete or more timely sources of information. Any reliance on the material on this site is at your own risk.</p>
        <p>We reserve the right to modify the contents of this site at any time, but we have no obligation to update any information on our site. You agree that it is your responsibility to monitor changes to our site.</p>
    </div>

    <div>
        <h4>Modifications to the Service and Prices</h4>
        <p>Prices for our products are subject to change without notice.</p>
        <p>We reserve the right at any time to modify or discontinue the Service (or any part or content thereof) without notice at any time.</p>
        <p>We shall not be liable to you or to any third-party for any modification, price change, suspension or discontinuance of the Service.</p>
    </div>

    <div>
        <h4>Accuracy of Billing and Account Information</h4>
        <p>We reserve the right to refuse any order you place with us. We may, in our sole discretion, limit or cancel quantities purchased per person, per household or per order. These restrictions may include orders placed by or under the same customer account, the same credit card, and/or orders that use the same billing and/or shipping address. In the event that we make a change to or cancel an order, we may attempt to notify you by contacting the e-mail and/or billing address/phone number provided at the time the order was made.</p>
        <p>We reserve the right to limit or prohibit orders that, in our sole judgment, appear to be placed by dealers, resellers or distributors.</p>
        <p>You agree to provide current, complete and accurate purchase and account information for all purchases made at our store. You agree to promptly update your account and other information, including your email address and credit card numbers and expiration dates, so that we can complete your transactions and contact you as needed.</p>
    </div>
    </div>
    </div>
  )
}

export default TermsCondition
