import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardContent, Container, Grid, Typography, useTheme, useMediaQuery } from '@mui/material';
import { useSpring, animated, config } from 'react-spring';
import AddIcon from '@mui/icons-material/Add';
import TableChartIcon from '@mui/icons-material/TableChart';
import PeopleIcon from '@mui/icons-material/People';
import { Casino } from '@mui/icons-material';
import axios from 'axios';

function AnimatedCard({ children, to }) {
  const [hovered, setHovered] = React.useState(false);

  const cardProps = useSpring({
    transform: hovered ? 'scale(1.05) translateY(-10px)' : 'scale(1) translateY(0px)',
    config: config.default,
  });

  return (
    <Link to={to} style={{ textDecoration: 'none', color: 'inherit' }}>
      <animated.div
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        style={{ ...cardProps, cursor: 'pointer' }}
      >
        {children}
      </animated.div>
    </Link>
  );
}

function AnimatedIcon({ icon, title, description, to }) {
  const [hovered, setHovered] = React.useState(false);

  const iconProps = useSpring({
    transform: hovered ? 'scale(1.1) translateY(-10px)' : 'scale(1) translateY(0px)',
    config: config.default,
  });

  return (
    <Link to={to} style={{ textDecoration: 'none', color: 'inherit' }}>
      <animated.div
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        style={{ ...iconProps, display: 'inline-block' }}
      >
        {icon}
        <Typography variant="h5" component="div" mt={2} sx={{ fontSize: { xs: '1.2rem', sm: '1.5rem' }, fontWeight: 600 }}>
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ fontSize: { xs: '0.9rem', sm: '1rem' } }}>
          {description}
        </Typography>
      </animated.div>
    </Link>
  );
}

function AdminHomePage() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [counts, setCounts] = useState({
    todayCount: 0,
    totalCount: 0,
    uniqueDistrictCount: 0,
    paymentsuccess:0,
    paymentCancelled:0,
    paymentFailure:0
  });

  useEffect(() => {
    const fetchCounts = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/adminCounts`,
         {
          headers: {
            'Content-Type': 'application/json',
            "Authorization": "$2a$12$tXgoc0t8Pfva7LmlyQ6/aOtB//vaEWOcdV3ElI82pAlevLYSLeg6W"
          }
        });
        setCounts(response.data);
      } catch (error) {
        console.error('Error fetching counts:', error);
      }
    };

    fetchCounts();
  }, []);

  const containerStyle = {
    background: '#f7f7f7',
    minHeight: '100vh',
    padding: '20px',
  };

  const cardStyles = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '10px',
    padding: '20px',
    margin: '10px 0',
    height: '100%',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  };

  const countCardColors = ['#f0a500', '#3498db', '#e74c3c'];
  const featureCardColors = ['#2ecc71', '#3498db', '#9b59b6', '#e74c3c'];

  const countCards = [
    {
      title: 'Today Count',
      icon: <Typography variant="h1" sx={{ fontSize: isMobile ? '3rem' : '4rem', color: '#2d3e50' }}>{counts.todayCount}</Typography>,
      to: '/purchasedetailsvib',
    },
    {
      title: 'Total Count',
      icon: <Typography variant="h1" sx={{ fontSize: isMobile ? '3rem' : '4rem', color: '#2d3e50' }}>{counts.totalCount}</Typography>,
      to: '/purchasedetailsvib',
    },
    {
      title: 'District Count',
      icon: <Typography variant="h1" sx={{ fontSize: isMobile ? '3rem' : '4rem', color: '#2d3e50' }}>{counts.uniqueDistrictCount}</Typography>,
      to: '/purchasedetailsvib',
    },
    {
      title: 'Payment Success',
      icon: <Typography variant="h1" sx={{ fontSize: isMobile ? '3rem' : '4rem', color: '#2d3e50' }}>{counts.paymentsuccess}</Typography>,
      to: '/purchasedetailsvib',
    },
    {
      title: 'Payment Cancelled',
      icon: <Typography variant="h1" sx={{ fontSize: isMobile ? '3rem' : '4rem', color: '#2d3e50' }}>{counts.paymentCancelled}</Typography>,
      to: '/purchasedetailsvib',
    },
    {
      title: 'Payment Failure',
      icon: <Typography variant="h1" sx={{ fontSize: isMobile ? '3rem' : '4rem', color: '#2d3e50' }}>{counts.paymentFailure}</Typography>,
      to: '/purchasedetailsvib',
    },
  ];

  const featureCards = [
    {
      title: 'Add Employee',
      description: 'Click here to add a new employee to the system.',
      icon: <AddIcon style={{ fontSize: isMobile ? 30 : 50 }} />,
      to: '/AgentRegister',
    },
    {
      title: 'Employee Details',
      description: 'View and manage the list of employees in the system.',
      icon: <TableChartIcon style={{ fontSize: isMobile ? 30 : 50 }} />,
      to: '/adminemployeetable',
    },
    {
      title: 'Customer Details',
      description: 'Explore and manage the list of customers in the system.',
      icon: <PeopleIcon style={{ fontSize: isMobile ? 30 : 50 }} />,
      to: '/purchasedetailsvib',
    },
    {
      title: 'Lucky Draw',
      description: 'Empower dream homes with personalized building experiences.',
      icon: <Casino style={{ fontSize: isMobile ? 30 : 50 }} />,
      to: '/luckydraw',
    },
  ];

  return (
    <div>
      <h1 style={responsiveHeadingStyle} className='text-center my-5'>Admin Dashboard</h1>

      <Container maxWidth="lg" className="" style={containerStyle}>
        <Grid container spacing={3}>
          {countCards.map((card, index) => (
            <Grid key={index} item xs={4} sm={4} md={4} >
              <AnimatedCard to={card.to}>
                <Card
                  style={{
                    ...cardStyles,
                    textAlign: "center"
                  }}
                >
                  <CardContent>
                    <AnimatedIcon
                      icon={card.icon}
                      title={card.title}
                      description={card.description}
                      to={card.to}
                    />
                  </CardContent>
                </Card>
              </AnimatedCard>
            </Grid>
          ))}
          {featureCards.map((card, index) => (
            <Grid key={index} item xs={6} sm={6} md={6} >
              <AnimatedCard to={card.to}>
                <Card
                  style={{
                    ...cardStyles,
                    backgroundColor: featureCardColors[index],
                    textAlign: "center"
                  }}
                >
                  <CardContent>
                    <AnimatedIcon
                      icon={card.icon}
                      title={card.title}
                      description={card.description}
                      to={card.to}
                    />
                  </CardContent>
                </Card>
              </AnimatedCard>
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
}
const responsiveHeadingStyle = {
  fontFamily: 'Arial, sans-serif',
  fontSize: '36px',
  fontWeight: 'bold',
  marginBottom: '20px',
  background: 'linear-gradient(to right, #3498db, #8e44ad)',
  WebkitBackgroundClip: 'text',
  color: 'transparent',
  textShadow: '0 2px 5px rgba(0, 0, 0, 0.3)',
  animation: 'fadeInUp 0.5s ease-in-out',

  // Responsive Font Size
  '@media (max-width: 768px)': {
    fontSize: '28px',
  },
  '@media (max-width: 576px)': {
    fontSize: '24px',
  },
};

export default AdminHomePage;
