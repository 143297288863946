// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toggle-switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 24px;
  }
  
  .toggle-switch input {
    display: none;
  }
  
  .toggle-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    border-radius: 24px;
    transition: 0.4s;
  }
  
  .toggle-slider:before {
    position: absolute;
    content: '';
    height: 18px;
    width: 18px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    border-radius: 50%;
    transition: 0.4s;
  }
  
  input:checked + .toggle-slider {
    background-color: #4caf50; /* Green background when toggled on */
  }
  
  input:checked + .toggle-slider:before {
    transform: translateX(26px);
  }`, "",{"version":3,"sources":["webpack://./src/Pages/PurchaseDetails/PurchaseDetails.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,qBAAqB;IACrB,WAAW;IACX,YAAY;EACd;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,kBAAkB;IAClB,eAAe;IACf,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;IACT,sBAAsB;IACtB,mBAAmB;IAEnB,gBAAgB;EAClB;;EAEA;IACE,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,WAAW;IACX,SAAS;IACT,WAAW;IACX,uBAAuB;IACvB,kBAAkB;IAElB,gBAAgB;EAClB;;EAEA;IACE,yBAAyB,EAAE,qCAAqC;EAClE;;EAEA;IAGE,2BAA2B;EAC7B","sourcesContent":[".toggle-switch {\n    position: relative;\n    display: inline-block;\n    width: 50px;\n    height: 24px;\n  }\n  \n  .toggle-switch input {\n    display: none;\n  }\n  \n  .toggle-slider {\n    position: absolute;\n    cursor: pointer;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    background-color: #ccc;\n    border-radius: 24px;\n    -webkit-transition: 0.4s;\n    transition: 0.4s;\n  }\n  \n  .toggle-slider:before {\n    position: absolute;\n    content: '';\n    height: 18px;\n    width: 18px;\n    left: 3px;\n    bottom: 3px;\n    background-color: white;\n    border-radius: 50%;\n    -webkit-transition: 0.4s;\n    transition: 0.4s;\n  }\n  \n  input:checked + .toggle-slider {\n    background-color: #4caf50; /* Green background when toggled on */\n  }\n  \n  input:checked + .toggle-slider:before {\n    -webkit-transform: translateX(26px);\n    -ms-transform: translateX(26px);\n    transform: translateX(26px);\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
