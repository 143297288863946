import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

const CancelScreen = () => {
    const location = useLocation();
  
    const [success, setSuccess] = useState({
        amount:'',
        order_id:'',
        currency:'',
        order_status:''
    });
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const encRequest = searchParams.get('encRequest');
    
        if (encRequest) {
          const decodedString = decodeURIComponent(encRequest);
          const params = new URLSearchParams(decodedString);
          const amount = params.get('amount');
          const order_id=params.get('order_id');
          const currency=params.get('currency');
          const order_status=params.get('order_status');
          if (amount) {
            console.log(amount);
            console.log(order_id);
            console.log(currency);
            console.log(order_status);
            setSuccess({
                amount,
                order_id,
                currency,
                order_status
            });
          }
        }
      }, [location.search]);
  return (
    <div style={{background:'linear-gradient(to bottom, white, #dadbad)'}}>
        <div className='container d-flex justify-content-center align-items-center vh-100'>
            <div className='bg-white h-50 col-3' style={{boxShadow: '0 0 10px 0 black',borderRadius:'5px',maxWidth:'350px'}}>
                <div className='d-flex-column h-50'>
                    <h2 className='text-center p-3'>Payment Cancelled</h2>
                    <div className='d-flex p-2 ml-5 gap-4'>
                        <label>Amount :</label>
                        <div>{success.amount}</div>
                    </div>
                    <div className='d-flex p-2 gap-4'>
                        <label>Order_id :</label>
                        <div>{success.order_id}</div>
                    </div>
                    <div className='d-flex p-2 gap-4'>
                        <label>Currency :</label>
                        <div>{success.currency}</div>
                    </div>
                    <div className=' d-flex p-2 gap-4'>
                        <label>Status :</label>
                        <div>{success.order_status}</div>
                    </div>
                    <div className='d-flex align-items-center justify-content-center'>
                        <Link to='/'className='w-50'><button style={{background:'#DF5A04'}}>Buy Again</button></Link>
                    </div>
                </div>
            </div>
        </div>s
    </div>
  )
}

export default CancelScreen