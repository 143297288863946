import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Navbar, Nav, Container, Button } from 'react-bootstrap';
import { FaHome, FaSignOutAlt, FaBars, FaTimes } from 'react-icons/fa';
import { BsPerson, BsTable, BsPeople } from 'react-icons/bs'; // Import icons from react-icons
import './NavBar.css';
import VIBLogo from '../../Images/VIB Logo.svg';

const NavBar = () => {
  const navigate = useNavigate();
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const handleLogout = () => {
    // Add logic to clear authentication token or perform logout actions
    localStorage.removeItem('token');
    window.location.href = '/vibadminlogin';
  };

  return (
    <>
      <div className='' style={{ paddingBottom: "75px" }}>
        <Navbar variant="dark" expand="lg" fixed="top" className="custom-navbar position-absolute" style={{ background: "#11113d" }}>
          <Container fluid className='px-3'>
            <Navbar.Brand as={Link} to="/adminhomepage" className="d-flex align-items-center">
              <img
                src={VIBLogo} // Replace with the path to your logo image
                width="80"
                height="60"
                className="d-inline-block align-top"
                alt="Your Logo"
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="navbar-nav" onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}>
              {isMobileMenuOpen ? <FaTimes /> : <FaBars />}
            </Navbar.Toggle>
            <Navbar.Collapse id="navbar-nav" className="justify-content-between">
              <Nav className={`mx-auto ${isMobileMenuOpen ? 'mobile-menu-open' : ''}`}>
                <Nav.Link as={Link} to="/adminhomepage">
                  <FaHome /> Home
                </Nav.Link>
                <Nav.Link as={Link} to="/AgentRegister">
                  <BsPerson /> Add Employee
                </Nav.Link>
                <Nav.Link as={Link} to="/adminemployeetable">
                  <BsTable /> Employee Details
                </Nav.Link>
                <Nav.Link as={Link} to="/purchasedetailsvib">
                  <BsPeople /> Customer Details
                </Nav.Link>
                {/* Add more navigation links as needed */}
              </Nav>
              <Nav className={`ml-auto ${isMobileMenuOpen ? 'mobile-menu-open' : ''}`}>
                <Button variant="outline-light" onClick={handleLogout}>
                  <FaSignOutAlt /> Logout
                </Button>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </>
  );
};

export default NavBar;
