// Install Toastr library if not already installed: npm install react-toastify
import React, { useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './AdminLoginPage.css';
import { useNavigate } from 'react-router-dom';

function AdminLoginPage() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

  

    const handleLogin = async () => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/api/auth/login`,
                { username, password },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        "Authorization": "$2a$12$tXgoc0t8Pfva7LmlyQ6/aOtB//vaEWOcdV3ElI82pAlevLYSLeg6W"
                    },
                }
            );

            const token = response.data.token;
            localStorage.setItem('token', token);

            // Use the navigate function to redirect upon successful login
            setTimeout(() => {
                window.location.href = '/adminhomepage';
            }, 1000);

            toast.success(' Login Successful!', { autoClose: 2000 });
            console.log('Login successfully');
        } catch (error) {
            console.error('Login failed', error.response?.data.message || 'An error occurred');

            let errorMessage = 'Login Failed. Please check your credentials.';

            // Customize error messages based on specific error scenarios
            if (error.response?.status === 401) {
                errorMessage = 'Invalid username or password. Please try again.';
            } else if (error.response?.status === 403) {
                errorMessage = 'You do not have permission to access. Please contact the administrator.';
            }

            toast.error(` ${errorMessage}`, { autoClose: 5000 });
        }
    };

    return (
        <>
            <div className='adminbody'>
                <div className='wrapper'>
                    <div className='heading'>
                        <h2>Venba Interlocks</h2>
                        <p>Admin Login</p>
                    </div>

                    <div className='input-group'>
                        <input
                            type='text'
                            id='username'
                            className='input-field'
                            placeholder='Username'
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                    </div>

                    <div className='input-group'>
                        <input
                            type='password'
                            id='password'
                            className='input-field'
                            placeholder='Password'
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>

                    <div className='input-group'>
                        <button onClick={handleLogin}>Login</button>
                    </div>
                </div>
                <ToastContainer />
            </div>
            {/* Toastr container for displaying messages */}
            
        </>
    );
}

export default AdminLoginPage;
