import { Email, Language, LocationOn, Phone } from '@mui/icons-material'
import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
  return (
    <>
    <div className='bg-primary'>
    <div className='container d-flex justify-content-between'>  
    <div className='container p-3 text-white'>
        <h3 className='text-white'>Venba Interlocks</h3>
        <p><Phone/> +91 90424 53530</p>
        <p><Email/> venbainterlockbrick@gmail.com </p>
        <p><Language/> www.venbainterlockbrick.com</p>
        <p><LocationOn/> #82G, Salembyepass karur 6 </p>
    </div>
    <div className='p-3 text-white m-3 col-4'>
        <h3>Services</h3>
        <Link to='/terms&condition' style={{textDecoration:'none',color:'white',cursor:'pointer'}}><p>Terms & Conditions</p></Link>
        <Link to='/returnpolicy' style={{textDecoration:'none',color:'white',cursor:'pointer'}}><p>Return Policy</p></Link>
    </div>   
    </div>
    </div>
    </>
  )
}

export default Footer