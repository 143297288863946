// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* AdminAddEmployeePage.css */

.adminAddEmployeeBody {
    background-color: #f8f9fa;
    padding: 20px;
  }
  
  .wrapper {
    max-width: 600px;
    margin: 0 auto;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .heading {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .photo-input {
    margin-bottom: 20px;
  }
  
  .custom-btn {
    padding: 10px;
    background-color: #11113d;
    color: #ffffff;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  .custom-btn:hover {
    background-color: #11113d;
  }
  
  /* Add more styling as needed */
  `, "",{"version":3,"sources":["webpack://./src/Pages/AdminAddEmployeePage/AdminAddEmployeePage.css"],"names":[],"mappings":"AAAA,6BAA6B;;AAE7B;IACI,yBAAyB;IACzB,aAAa;EACf;;EAEA;IACE,gBAAgB;IAChB,cAAc;IACd,yBAAyB;IACzB,aAAa;IACb,kBAAkB;IAClB,uCAAuC;EACzC;;EAEA;IACE,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,yBAAyB;IACzB,cAAc;IACd,YAAY;IACZ,eAAe;IACf,kBAAkB;IAClB,eAAe;IACf,sCAAsC;EACxC;;EAEA;IACE,yBAAyB;EAC3B;;EAEA,+BAA+B","sourcesContent":["/* AdminAddEmployeePage.css */\n\n.adminAddEmployeeBody {\n    background-color: #f8f9fa;\n    padding: 20px;\n  }\n  \n  .wrapper {\n    max-width: 600px;\n    margin: 0 auto;\n    background-color: #ffffff;\n    padding: 20px;\n    border-radius: 8px;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n  }\n  \n  .heading {\n    text-align: center;\n    margin-bottom: 20px;\n  }\n  \n  .photo-input {\n    margin-bottom: 20px;\n  }\n  \n  .custom-btn {\n    padding: 10px;\n    background-color: #11113d;\n    color: #ffffff;\n    border: none;\n    cursor: pointer;\n    border-radius: 4px;\n    font-size: 16px;\n    transition: background-color 0.3s ease;\n  }\n  \n  .custom-btn:hover {\n    background-color: #11113d;\n  }\n  \n  /* Add more styling as needed */\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
