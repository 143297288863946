import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom';

const SuccessScreen = () => {
    const location = useLocation();
  
    const [success, setSuccess] = useState({
        amount:'',
        order_id:'',
        currency:'',
        order_status:''
    });
    const[refcode,setRefCode]=useState('Pay to view Code'); 
    const [paymentText, setPaymentText] = useState('');
    useEffect(() => {
        // Update paymentText based on data.status when data changes
        if (success.order_status === 'Success') {
          setPaymentText('Payment Success');
        } else if (success.order_status === 'Failure') {
          setPaymentText('Payment Failed');
        }
      }, [success.order_status]);
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const encRequest = searchParams.get('encRequest');
    
        if (encRequest) {
          const decodedString = decodeURIComponent(encRequest);
          const params = new URLSearchParams(decodedString);
          const amount = params.get('amount');
          const order_id=params.get('order_id');
          const currency=params.get('currency');
          const order_status=params.get('order_status');
          if (amount) {
            console.log(amount);
            console.log(order_id);
            console.log(currency);
            console.log(order_status);
            setSuccess({
                amount,
                order_id,
                currency,
                order_status
            });
          }
        }
      }, [location.search]);

      useEffect(() => {
        const fetchData = async () => {
        try{
      const res=await axios.get(`${process.env.REACT_APP_BACKEND_URL}/refcode?id=${success.order_id}`);
      if(res.data.value.PaymentStatus==='Success'){
      setRefCode(res.data.value.ReferalCode);
      console.log(res.data);
      }
        }catch (error) {
            console.error('Error fetching collections:', error);
          }
    };
    fetchData();
    }, [success]);
  return (
    <div style={{background:'linear-gradient(to bottom, white, #dadbad)'}}>
    <div className='container d-flex flex-column justify-content-center align-items-center vh-100'>
            <div className=' bg-white col-3 h-50'style={{boxShadow: '0 0 10px 0 black',borderRadius:'5px'}}>
                <div className='d-flex-column h-50'>
                    <h2 className='text-center p-3'>{paymentText}</h2>
                    <div className='d-flex p-2 ml-5 gap-4'>
                        <label>Amount :</label>
                        <div>{success.amount}</div>
                    </div>
                    <div className='d-flex p-2 gap-4'>
                        <label>Order_id :</label>
                        <div>{success.order_id}</div>
                    </div>
                    <div className='d-flex p-2 gap-4'>
                        <label>Currency :</label>
                        <div>{success.currency}</div>
                    </div>
                    <div className=' d-flex p-2 gap-4'>
                        <label>Status :</label>
                        <div>{success.order_status}</div>
                    </div>
                    <div className='d-flex justify-content-center'>
                        <Link to='/' className='w-50'><button style={{background:'#DF5A04'}}>Try Again</button></Link>
                    </div>
                </div>
            </div>
            <div className='d-flex align-items-center justify-content-center mt-5'>
                <h3>{refcode}</h3>
            </div>
        </div>
    </div>
  )
}

export default SuccessScreen