import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import './PurchaseDetails.css';
import axios from 'axios';

function PurchaseDetails() {
  const [clientDatas, setClientDatas] = useState([]);
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(true); // New state for loading

  useEffect(() => {
    fetchClientDatas();
  }, []);

  const fetchClientDatas = () => {
    setLoading(true); // Set loading to true when fetching data
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/api/clientdatas`, {
        headers: {
          'Content-Type': 'application/json',
          "Authorization": "$2a$12$tXgoc0t8Pfva7LmlyQ6/aOtB//vaEWOcdV3ElI82pAlevLYSLeg6W"
        }
      })
      .then((response) => {
        setClientDatas(response.data.clientdatas);
        setRecords(response.data.clientdatas);
      })
      .catch((error) => console.error('Error fetching client datas:', error))
      .finally(() => setLoading(false)); // Set loading to false after data is fetched
  };
  // Log clientDatas whenever it changes
  useEffect(() => {
    console.log(clientDatas);
  }, [clientDatas]);

  const handleClickTop = () => {

    // Use window.scrollTo to scroll to the top
    window.scrollTo(0, 0);
  };

  const columns = [
    {
      name: 'order_id',
      selector: 'id',
      sortable: true,
    },
    {
      name: 'Name',
      selector: 'Name',
      sortable: true,
    },
    {
      name: 'Referral Code',
      selector: 'ReferalCode',
      sortable: true,
    },
    {
      name: 'District',
      selector: 'District',
      sortable: true,
    },
    {
      name: 'Date Of Registration',
      selector: 'createdAt',
      sortable: true,
      // Format date to show only the date part
      cell: (row) => new Date(row.createdAt).toLocaleDateString(),
    },
    {
      name: 'Employee Code',
      selector: row => row.EmployeeCode ? row.EmployeeCode : 'Self',
      sortable: true,
    },
    {
      name: 'PaymentMode',
      selector: 'PaymentMode',
      sortable: true,
    },
    {
      name: 'PaymentStatus',
      selector: 'PaymentStatus',
      sortable: true,
    },
    {
      name: 'Details',
      selector: '_id',
      cell: (row) => (
        <Link to={`/customerindividualpage/${row._id}`} onClick={handleClickTop}>
          <button className='border rounded bg-info text-white p-2' style={{ fontSize: '12px' }}>
            View Details
          </button>
        </Link>
      ),
    },
    {
      name: 'Approval Status',
      selector: 'ApprovalStatus',
      sortable: true,
      cell: (row) => (
        <div className='d-flex flex-column'>
          {row.ApprovalStatus !== 'Approved' ? (
            <>
              <span className='text-center'>{row.ApprovalStatus}</span>
              <button
                className='border btn rounded bg-primary text-white'
                onClick={() => handleStatusChange(row._id)}
                style={{ fontSize: '12px' }}
              >
                Change Status
              </button>
            </>
          ) : (
            <span className='text-center'>{row.ApprovalStatus}</span>
          )}
        </div>
      ),
    },

    {
      name: 'Issued Status',
      selector: 'IssuedStatus',
      cell: (row) => (
        <label className="toggle-switch">
          <input
            type="checkbox"
            checked={row.IssuedStatus}
            onChange={() => handleToggleIssuedStatus(row._id, row.ApprovalStatus, row.IssuedStatus)}
            disabled={row.ApprovalStatus !== 'Approved' || row.IssuedStatus}
          />
          <span className="toggle-slider"></span>
        </label>
      ),
    },

  ];

  // Loading spinner component
  const LoadingSpinner = () => (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );


  const [selectedRows, setSelectedRows] = useState([]);
  const [isChangeStatusButtonDisabled, setIsChangeStatusButtonDisabled] = useState(true);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const fetchDataBetweenDates = () => {
    const filteredData = clientDatas.filter((row) => {
      const createdAt = new Date(row.createdAt).getTime();
      const startDateTime = startDate ? new Date(startDate).setHours(0, 0, 0, 0) : 0;
      const endDateTime = endDate ? new Date(endDate).setHours(23, 59, 59, 999) : new Date().getTime();

      return createdAt >= startDateTime && createdAt <= endDateTime;
    });

    setRecords(filteredData);
  };

  useEffect(() => {
    fetchDataBetweenDates();
  }, [startDate, endDate]);


  useEffect(() => {
    setIsChangeStatusButtonDisabled(selectedRows.length === 0);
  }, [selectedRows]);

  const handleIssuedStatusChange = (rowId) => {
    Swal.fire({
      title: 'Change Issued Status',
      text: 'Are you sure you want to change the Issued status?',
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        handleIssuedStatusUpdate(rowId);
      }
    });
  };

  const handleIssuedStatusUpdate = async (rowId) => {
    try {
      // Make a PUT request to update the Issued status
      await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/clientdataissuedstatus/${rowId}`, {
        IssuedStatus: true, // or false, depending on your requirement
      }, {
        headers: {
          'Content-Type': 'application/json',
          "Authorization": "$2a$12$tXgoc0t8Pfva7LmlyQ6/aOtB//vaEWOcdV3ElI82pAlevLYSLeg6W"
        }
      });

      // Update the local records with the new Issued status
      const updatedData = records.map((r) =>
        r._id === rowId ? { ...r, IssuedStatus: !r.IssuedStatus } : r
      );
      setRecords(updatedData);

      fetchClientDatas();


    } catch (error) {
      console.error('Error updating Issued status:', error);
    }
  };


  const handleToggleIssuedStatus = (rowId, approvalStatus, issuedStatus) => {
    if (approvalStatus !== 'Approved' || issuedStatus) {
      // If not approved or already issued, do nothing
      return;
    }

    const updatedData = records.map((r) =>
      r._id === rowId ? { ...r, IssuedStatus: !r.IssuedStatus } : r
    );
    setRecords(updatedData);

    // Call the function to handle Issued Status update
    handleIssuedStatusChange(rowId);
  };


  const handleStatusChange = (rowId) => {
    Swal.fire({
      title: 'Change Approval Status',
      text: 'Choose the new approval status:',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Approve',
      cancelButtonText: 'Reject',
      showCloseButton: true,
      showDenyButton: true,
      denyButtonText: 'Pending',
    }).then((result) => {
      if (result.isConfirmed) {
        handleStatusUpdate(rowId, 'Approved');
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        handleStatusUpdate(rowId, 'Rejected');
      } else if (result.isDenied) {
        handleStatusUpdate(rowId, 'Pending');
      }
    });
  };

  const handleStatusUpdate = async (rowId, status) => {
    try {
      // Make a PUT request to update the approval status
      await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/clientdataapproval/${rowId}`,
        { ApprovalStatus: status, },
        {
          headers: {
            'Content-Type': 'application/json',
            "Authorization": "$2a$12$tXgoc0t8Pfva7LmlyQ6/aOtB//vaEWOcdV3ElI82pAlevLYSLeg6W"
          }
        });

      // Update the local records with the new approval status
      const updatedData = records.map((r) =>
        r._id === rowId ? { ...r, ApprovalStatus: status } : r
      );
      setRecords(updatedData);

      // Display success message based on the updated approval status
      if (status === 'Approved') {
        Swal.fire('Approval Status Updated', 'The status has been set to Approved', 'success');
      } else if (status === 'Rejected') {
        Swal.fire('Approval Status Updated', 'The status has been set to Rejected', 'error');
      } else if (status === 'Pending') {
        Swal.fire('Approval Status Updated', 'The status has been set to Pending', 'info');
      }
      fetchClientDatas();
    } catch (error) {
      console.error('Error updating approval status:', error);
    }
  };


  const handleBulkStatusChange = () => {
    if (selectedRows.length === 0) return;

    Swal.fire({
      title: 'Change Approval Status',
      text: 'Choose the new approval status:',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Approve',
      cancelButtonText: 'Reject',
      showCloseButton: true,
      showDenyButton: true,
      denyButtonText: 'Pending',
    }).then((result) => {
      if (result.isConfirmed) {
        handleBulkStatusUpdate('Approved');
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        handleBulkStatusUpdate('Rejected');
      } else if (result.isDenied) {
        handleBulkStatusUpdate('Pending');
      }
    });
  };

  const handleBulkStatusUpdate = async (status) => {
    try {
      // Check if all selected rows are already approved
      const allApproved = selectedRows.every(
        (rowId) => records.find((row) => row._id === rowId).ApprovalStatus === 'Approved'
      );

      if (allApproved) {
        Swal.fire('Cannot Update', 'All selected rows are already Approved', 'warning');
        return;
      }

      // Filter out rows that are already approved
      const rowsToApprove = selectedRows.filter(
        (rowId) => records.find((row) => row._id === rowId).ApprovalStatus !== 'Approved'
      );

      // Prepare an array of promises for all selected rows to update approval status
      const updatePromises = rowsToApprove.map(async (rowId) => {
        await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/clientdataapproval/${rowId}`, {
          ApprovalStatus: status,
        }, {
          headers: {
            'Content-Type': 'application/json',
            "Authorization": "$2a$12$tXgoc0t8Pfva7LmlyQ6/aOtB//vaEWOcdV3ElI82pAlevLYSLeg6W"
          }
        });
      });

      // Wait for all update promises to resolve
      await Promise.all(updatePromises);

      // Update the local records with the new approval status
      const updatedData = records.map((row) => {
        const shouldUpdate = selectedRows.includes(row._id) && !rowsToApprove.includes(row._id);
        return shouldUpdate ? { ...row, ApprovalStatus: status } : row;
      });
      setRecords(updatedData);

      // Display success message based on the updated approval status
      if (status === 'Approved') {
        Swal.fire('Approval Status Updated', 'The status has been set to Approved', 'success');
      } else if (status === 'Rejected') {
        Swal.fire('Approval Status Updated', 'The status has been set to Rejected', 'error');
      } else if (status === 'Pending') {
        Swal.fire('Approval Status Updated', 'The status has been set to Pending', 'info');
      }

      fetchClientDatas();

      setSelectedRows([]); // Clear selected rows after the update.
    } catch (error) {
      console.error('Error updating bulk approval status:', error);
    }
  };


  const handleSearch = (event) => {
    const searchValue = event.target.value.toLowerCase();

    const newData = clientDatas.filter((row) => {
      return (
        (row.Name && row.Name.toLowerCase().includes(searchValue)) ||
        (row.ReferalCode && row.ReferalCode.toLowerCase().includes(searchValue)) ||
        (row.createdAt && new Date(row.createdAt).toLocaleDateString().includes(searchValue)) ||
        (row.EmployeeCode && row.EmployeeCode.toString().includes(searchValue)) ||
        (row.ApprovalStatus && row.ApprovalStatus.toLowerCase().includes(searchValue))
      );
    });
    setRecords(newData);
  };





  return (

    <>
      <div className="container-fluid my-5">
        <div className='px-5'>
          <h1 style={responsiveHeadingStyle} className='text-center'>Customer Details</h1>

          {loading ? (<LoadingSpinner />
          ) : (
            // Display data if loading is false

            <>
              <div className="my-3 d-flex flex-wrap justify-content-evenly align-items-center">
                <button
                  className="btn btn-primary col-12 col-md-3 mb-2 mb-md-0"
                  onClick={handleBulkStatusChange}
                  disabled={isChangeStatusButtonDisabled}
                >
                  Change Status
                </button>

                <div className="form-group mb-2">
                  <label htmlFor="startDate" className="mr-2">
                    From Date:
                  </label>
                  <input
                    type="date"
                    id="startDate"
                    className="form-control date-input"
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                </div>

                <div className="form-group mb-2">
                  <label htmlFor="endDate" className="mr-2">
                    To Date:
                  </label>
                  <input
                    type="date"
                    id="endDate"
                    className="form-control date-input"
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                </div>
                <input
                  type="text"
                  className="form-control rounded col-12 col-md-4 mb-2 mb-md-0"
                  placeholder="Search for Name ,District ,Referal Code ,Approval Status ,Date of Registration and Employee Code"
                  onChange={handleSearch}
                />
              </div>
              <DataTable
                columns={columns}
                data={records}
                selectableRows
                onSelectedRowsChange={({ selectedRows }) => setSelectedRows(selectedRows.map((row) => row._id))}
                fixedHeader
                pagination
                responsive
                striped
                highlightOnHover
                customStyles={{
                  headRow: {
                    style: {
                      backgroundColor: '#3498db',
                      color: '#fff',
                      fontSize: '16px',
                      fontWeight: 'bold',
                      border: '1px solid #ddd', // Added border style
                    },
                  },
                  headCells: {
                    style: {
                      paddingLeft: '8px',
                      paddingRight: '8px',
                    },
                  },
                  rows: {
                    style: {
                      fontSize: '14px',
                      border: '1px solid #ddd', // Added border style
                    },
                  },
                  cells: {
                    style: {
                      paddingLeft: '8px',
                      paddingRight: '8px',
                      wordBreak: 'break-word',
                    },
                  },
                }}
              />

            </>
          )}
        </div>
      </div>
    </>
  );
}

const responsiveHeadingStyle = {
  fontFamily: 'Arial, sans-serif',
  fontSize: '36px',
  fontWeight: 'bold',
  marginBottom: '20px',
  background: 'linear-gradient(to right, #3498db, #8e44ad)',
  WebkitBackgroundClip: 'text',
  color: 'transparent',
  textShadow: '0 2px 5px rgba(0, 0, 0, 0.3)',
  animation: 'fadeInUp 0.5s ease-in-out',

  '@media (max-width: 768px)': {
    fontSize: '28px',
  },
  '@media (max-width: 576px)': {
    fontSize: '24px',
  },
};

export default PurchaseDetails;
